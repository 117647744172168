export default [
    {
        month: 'Setembro 2024',
        winners: [{
            name: 'Kátia Ricarte de Moura',
            location: 'Taubaté - SP'
        }],
    },
    {
        month: 'Agosto 2024',
        winners: [{
            name: 'Richardson Silva Lima',
            location: 'Brasília - DF'
        }],
    },
    {
        month: 'Julho 2024',
        winners: [{
            name: 'Ezio Mello',
            location: 'Votuporanga- SP'
        }],
    },
    {
        month: 'Junho 2024',
        winners: [{
            name: 'Rinaldo Batista de Oliveira',
            location: 'Brodowski - SP'
        }],
    },
    {
        month: 'Maio 2024',
        winners: [{
            name: 'Andriely Godoi Vieira',
            location: 'Aparecida de Goiânia - GO'
        }],
    },
    {
        month: 'Abril 2024',
        winners: [{
            name: 'Francisco de Andrade Lourenção Freddi',
            location: 'São Paulo - SP'
        }],
    },
    {
        month: 'Março 2024',
        winners: [{
            name: 'Casusa Heiderich Cordeiro Nunes',
            location: 'Nova Friburgo - RJ'
        }],
    },
    {
        month: 'Fevereiro 2024',
        winners: [{
            name: 'Queli Santina dos Anjos',
            location: 'Mongaguá - SP'
        }],
    },
    {
        month: 'Janeiro 2024',
        winners: [{
            name: 'Renato Pedro Rodrigues da Silva',
            location: 'São José dos Campos - SP'
        }],
    },
    {
        month: 'Dezembro 2023',
        winners: [{
            name: 'Michel Pablo Maliuk',
            location: 'Santana do Livramento - RS'
        }],
    },
    {
        month: 'Novembro 2023',
        winners: [{
            name: 'Antônio Pires Medeiros',
            location: 'Birigui - SP'
        }],
    },
    {
        month: 'Outubro 2023',
        winners: [{
            name: 'Guilherme Luiz',
            location: 'Diadema - SP'
        }
        ],
    },
    {
        month: 'Setembro 2023',
        winners: [{
            name: 'Ana Flavia Barreiros Albino',
            location: 'Teofilo Otoni - MG'
        }
        ],
    },
    {
        month: 'Agosto 2023',
        winners: [{
            name: 'Ricardo Camargo Pantaleão',
            location: 'São Paulo - SP'
        }
        ],
    },
    {
        month: 'Julho 2023',
        winners: [{
            name: 'Lidiana Mistica Melo Sacremento',
            location: 'Viçosa - MG'
        }
        ],
    },
    {
        month: 'Março 2023',
        winners: [{
            name: 'Rafael Rodrigues de Lima',
            location: 'Ubatuba - SP'
        }
        ],
    },
    {
        month: 'Fevereiro 2023',
        winners: [{
            name: 'Igor Luis Bintecur Bonfim',
            location: 'Salvador - BA'
        }
        ],
    },
    {
        month: 'Janeiro 2023',
        winners: [{
            name: 'Pedro de Souza Cruz',
            location: 'Juazeiro - BA'
        }
        ],
    },
    {
        month: 'Dezembro 2022',
        winners: [{
            name: 'Fabio Taniwaki',
            location: 'Mogi das Cruzes - SP'
        }
        ],
    },
    {
        month: 'Novembro 2022',
        winners: [{
            name: 'Adilson Alves Santos',
            location: 'Embu das Artes - SP'
        }
        ],
    },
    {
        month: 'Outubro 2022',
        winners: [{
            name: 'Severino dos Ramos Bernardo',
            location: 'São José dos Campos - SP'
        }
        ],
    },
    {
        month: 'Setembro 2022',
        winners: [{
            name: 'Luciano Aszis Carneiro',
            location: 'Ponta Grossa - PR'
        }
        ],
    },
    {
        month: 'Agosto 2022',
        winners: [{
            name: 'Jose Francisco Moreira Lima',
            location: 'Caxias - MA'
        }
        ],
    },
    {
        month: 'Julho 2022',
        winners: [{
            name: 'Fabia Bianco',
            location: 'São Paulo - SP'
        }
        ],
    },
    {
        month: 'Junho 2022',
        winners: [{
            name: 'Marcelio Vagner Cavalcante da Silva',
            location: 'Diadema - SP'
        }
        ],
    },
    {
        month: 'Maio 2022',
        winners: [{
            name: 'Matheus Daniel Bueno',
            location: 'Santa Rita do Sapucaí - MG'
        }
        ],
    },
    {
        month: 'Abril 2022',
        winners: [{
            name: 'Arineia Maria Xavier Pasquini',
            location: 'São Caetano do Sul - SP'
        },
        {
            name: 'Hegon Henrique Lages Pereira',
            location: 'Novo Cruzeiro - MG'
        },
        {
            name: 'Patricia Freitas Hayashi',
            location: 'Caxias do Sul - RS'
        },
        {
            name: 'Washington Lima da Silva',
            location: 'Guapiaçu - SP'
        },
        {
            name: 'Michelly dos Santos Campos de Lima',
            location: 'Rio de Janeiro - RJ'
        },
        ],
    },
    {
        month: 'Março 2022',
        winners: [{
            name: 'Ana Paula Marso',
            location: 'Taboão da Serra - SP'
        },
        {
            name: 'Gelciara Villeth Mattos',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Iara de Jesus',
            location: 'São Paulo - SP'
        },
        {
            name: 'Alessandra Greice da Silva Neves',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Fábio Soares de Oliveira',
            location: 'Porto Alegre - RS'
        },
        ],
    },
    {
        month: 'Fevereiro 2022',
        winners: [{
            name: 'Luan Lima da Silva',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Vanderlei Rodrigues de Araujo',
            location: 'Mogi das Cruzes - SP'
        },
        {
            name: 'Iorrana Defensor Baptista Porta',
            location: 'Rio de Contas - BA'
        },
        {
            name: 'Iasmin Nunes Silva',
            location: 'Goiânia - GO'
        },
        {
            name: 'Daniele Nunes Coelho',
            location: 'Salto - SP'
        },
        ],
    },
    {
        month: 'Janeiro 2022',
        winners: [{
            name: 'Anderson Botinhão',
            location: 'São Paulo – SP'
        },
        {
            name: 'Renildo de Lima Santos',
            location: 'Santo Antônio de Jesus – BA'
        },
        {
            name: 'Joelma Atanasio de Paula',
            location: 'Rio de Janeiro – RJ'
        },
        {
            name: 'Diego Moreira Teixeira da Silva',
            location: 'São João de Meriti – RJ'
        },
        {
            name: 'Vanderlei Rodrigues de Araújo',
            location: 'Mogi das Cruzes - SP'
        },
        ],
    },
    {
        month: 'Dezembro 2021',
        winners: [{
            name: 'Katia da Silva Barcelos',
            location: 'Nova Friburgo - RJ'
        },
        {
            name: 'Elisangela Heidrich',
            location: 'Parobé - RS'
        },
        {
            name: 'Carla Aparecida Fidencio Silva',
            location: 'São José dos Campos - SP'
        },
        {
            name: 'Douglas Franco de Souza',
            location: 'Carapicuíba - SP'
        },
        {
            name: 'Antonio Carlos dos Santos',
            location: 'São Carlos - SP'
        },
        ],
    },
    {
        month: 'Novembro 2021',
        winners: [{
            name: 'Rafael Barsi',
            location: 'Campinas - SP'
        },
        {
            name: 'Silvano Carvalho Junior',
            location: 'Guarujá - SP'
        },
        {
            name: 'Lucas Alcantara Dias',
            location: 'Rio de Janeiro – RJ'
        },
        {
            name: 'Carmen Iolanda Peixoto Fremeaux',
            location: 'Rio de Janeiro – RJ'
        },
        {
            name: 'Willian Matheus Zahn dos Santos',
            location: 'Santa Cruz do Sul – RS'
        },
        ],
    },
    {
        month: 'Outubro 2021',
        winners: [{
            name: 'Sandra Francisco',
            location: 'São Paulo - SP'
        },
        {
            name: 'Tainara Matos Santos',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Mauricio de Souza Real',
            location: 'Atibaia - SP'
        },
        {
            name: 'Antonio Carlos Cabral',
            location: 'Pindamonhangaba - SP'
        },
        {
            name: 'Kelly Knosel da Costa Grachet',
            location: 'Rio de Janeiro - RJ'
        },
        ],
    },
    {
        month: 'Setembro 2021',
        winners: [{
            name: 'Luciano dos Santos Oliveira ',
            location: 'Barretos - SP'
        },
        {
            name: 'Rosa Maria Barreto Motta Ferreira',
            location: 'Macaé - RJ'
        },
        {
            name: 'Robert Wagner Matos da Silva',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Julia Motta de Souza',
            location: 'Campos dos Goytacazes - RJ'
        },
        {
            name: 'Hugo Anderson',
            location: 'Recife - PE'
        },
        ],
    },
    {
        month: 'Agosto 2021',
        winners: [{
            name: 'Reginaldo Aparecido Feitosa de Oliveira',
            location: 'São Paulo - SP'
        },
        {
            name: 'Marcelo Augusto Pinto',
            location: 'Itapira - SP'
        },
        {
            name: 'Neide Mendonça de Farias',
            location: 'Cabo Frio - RJ'
        },
        {
            name: 'Aparecida Batista Novaes de Oliveira',
            location: 'Presidente Prudente - SP'
        },
        {
            name: 'Cristian Luciandro Nunes Farias',
            location: 'Caxias do Sul - RS'
        },
        ],
    },
    {
        month: 'Julho 2021',
        winners: [{
            name: 'Daniele Lopes Parra',
            location: 'São Bernardo do Campo - SP'
        },
        {
            name: 'Douglas Franco de Souza',
            location: 'Carapicuíba - SP'
        },
        {
            name: 'Renildo de Lima Santos',
            location: 'Santo Antônio de Jesus - BA'
        },
        {
            name: 'Paulo Henrique Cazula De Oliveira',
            location: 'Curitiba - PR'
        },
        {
            name: 'Silvana Barreto Motta',
            location: 'Campos dos Goytacazes - RJ'
        },
        ],
    }, {
        month: 'Junho 2021',
        winners: [{
            name: 'Francinaldo Araújo da Silva',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Elvis Xavier de Matos',
            location: 'São Paulo - SP'
        },
        {
            name: 'Thiago Wilson Rodrigues da Costa',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Leandro Fileno Batista',
            location: 'Guarulhos - SP'
        },
        {
            name: 'Diego Barbosa Dos Santos',
            location: 'Capão da Canoa - RS'
        },
        ],
    }, {
        month: 'Maio 2021',
        winners: [{
            name: 'Leandro Oliveira Marin',
            location: 'Ribeirão Preto - SP'
        },
        {
            name: 'João Paulo Machado Martins',
            location: 'São Paulo - SP'
        },
        {
            name: 'Ana Christina Santana',
            location: 'São Paulo - SP'
        },
        {
            name: 'Roberto Possani Marques ',
            location: 'São Vicente - SP'
        },
        {
            name: 'Marcos Roberto Dos Santos Delmondes',
            location: 'Porto Seguro - BA'
        },
        ]
    }, {
        month: 'Abril 2021',
        winners: [{
            name: 'Liliane Morais Silveira',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Vinicius Martiniano Hipolito do Amaral',
            location: 'Jandira - SP'
        },
        {
            name: 'Rafael Oliveira Toná ',
            location: 'Fazenda Rio Grande - PR'
        },
        {
            name: 'Maria Clara Souza Chiesorin',
            location: 'Curitiba - PR'
        },
        {
            name: 'Lilian da Silva Santos',
            location: 'Petrópolis - RJ'
        },
        ]
    }, {
        month: 'Março 2021',
        winners: [{
            name: 'Marcello Luis Moreira Pires',
            location: 'Niterói - RJ'
        },
        {
            name: 'Leila Costa Da Silva Correia ',
            location: 'Nova Iguaçu - RJ'
        },
        {
            name: 'Marlene da Motta Sobrinha ',
            location: 'Jandira - SP'
        },
        {
            name: 'Keyce Santos Azevedo',
            location: 'Armação dos Búzios - RJ'
        },
        {
            name: 'Jéssica Patrícia Alves de Assis',
            location: 'Curitiba - PR'
        },
        ]
    }, {
        month: 'Fevereiro 2021',
        winners: [{
            name: 'Elton Lima Ribeiro',
            location: 'Juquiá - SP'
        },
        {
            name: 'Mário Junior Costa de mattos',
            location: 'Porto Alegre - RS'
        },
        {
            name: 'Daniel Dantas',
            location: 'Sorocaba - SP'
        },
        {
            name: 'Luana Petrozzelli Dias',
            location: ' Diadema - SP'
        },
        {
            name: 'Rita de Cássia Nunes de Carvalho',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Rafael Oliveira Toná ',
            location: 'Fazenda Rio Grande - PR'
        },
        {
            name: 'Iago Luiz Gonçalves Rodrigues da Silva ',
            location: 'Nova Friburgo - RJ'
        },
        {
            name: 'Heloisa Francisca dos Santos Gomes',
            location: 'Nova Iguaçu - RJ'
        },
        {
            name: 'Valdiney de Jesus do Rosario',
            location: 'Taubaté - SP'
        },
        {
            name: 'Alvaro Luiz Possani Marques',
            location: 'Santos - SP'
        },
        ]
    }, {
        month: 'Janeiro 2021',
        winners: [{
            name: 'Nelson J. B. da Silva',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Elias Ferreira Alencar',
            location: 'Santos - SP'
        },
        {
            name: 'Joseani Daniela Auler',
            location: 'São José do Hortêncio - RS'
        },
        {
            name: 'Alana Caroline da Silva',
            location: 'Suzano - SP'
        },
        {
            name: 'Ana Paula Braz dos Santos ',
            location: 'Ribeirão Preto - SP'
        },
        {
            name: 'Katiane Maria de Jesus',
            location: 'Cotia - SP'
        },
        {
            name: 'Hayssa Jorge Gonçalves Terra Alves',
            location: 'Campos dos Goytacazes - RJ'
        },
        {
            name: 'Luciana do Nascimento Bacelar dos Reis',
            location: 'Cascavel - PR'
        },
        {
            name: 'Mauricio de Souza Real ',
            location: 'Atibaia - SP'
        },
        {
            name: 'Marcos Aparecido Lima da Silva',
            location: 'Guarulhos - SP'
        },
        ]
    }, {
        month: 'Dezembro 2020',
        winners: [{
            name: 'Dayana Dias Pareschi',
            location: 'Duque de Caxias - RJ'
        },
        {
            name: 'Valéria da Luz Mendonça',
            location: 'Rosário do Sul - RS'
        },
        {
            name: 'Carla Valéria Rangel da Silva',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Fernando Menezes Lima',
            location: 'São Paulo - SP'
        },
        {
            name: 'Talyta Souza Teixeira',
            location: 'São João da Boa Vista - SP'
        },
        {
            name: 'Rafael Pessanha Nunes',
            location: 'Macaé - RJ'
        },
        {
            name: 'Marcia Souto Costa',
            location: 'Nilópolis - RJ'
        },
        {
            name: 'Giullia Ferreira',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Rafael Silva Miranda',
            location: 'Suzano - SP'
        },
        {
            name: 'Carlos Simões Pinto Junior',
            location: 'São Paulo - SP'
        },
        ]
    }, {
        month: 'Novembro 2020',
        winners: [{
            name: 'Eunice Toshie Shinmachi Silva',
            location: 'São Caetano do Sul - SP'
        },
        {
            name: 'Bruno Cesar Silveira de Almeida',
            location: 'Piracicaba - SP'
        },
        {
            name: 'Beatriz Ribeiro da Silva',
            location: 'Duque de Caxias - RJ'
        },
        {
            name: 'Caio Correia de Oliveira',
            location: 'Nova Iguaçu - RJ'
        },
        {
            name: 'Miroel José Santana Junior',
            location: 'São Paulo - SP'
        },
        {
            name: 'Mateus Ramos Ortolani',
            location: 'Piratininga - SP'
        },
        {
            name: 'Robson Bianco Ruiz',
            location: 'Sumaré - SP'
        },
        {
            name: 'Rafael Natal',
            location: 'Araçatuba - SP'
        },
        {
            name: 'Alander Sidnei Adao',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Renata Marcondes',
            location: 'Rio das Ostras - RJ'
        },
        ]
    }, {
        month: 'Outubro 2020',
        winners: [{
            name: 'Mariel Rocha de Jesus',
            location: 'São Paulo - SP'
        },
        {
            name: 'Vladimir Bueno dos Santos',
            location: 'Porto Alegre - RS'
        },
        {
            name: 'Dalila Joyce Teixeira Magalhães Araújo',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'José rodrigues chaves',
            location: 'São Paulo - SP'
        },
        {
            name: 'Marciane Leal Gonçalves Marques',
            location: 'São Paulo - SP'
        },
        {
            name: 'Eder Bortolotto Silva',
            location: 'Bento Gonçalves - RS'
        },
        {
            name: 'Felipe Costa Carrascosa',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'juliana Faleiro de souza Dornelles',
            location: 'General Câmara - RS'
        },
        {
            name: 'Valéria merlim Martins de carvalho',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Ana Claudia da Silva',
            location: 'Macaé - RJ'
        },
        ]
    }, {
        month: 'Setembro 2020',
        winners: [{
            name: 'Danilo da Silva',
            location: 'Embu-Guaçu - SP'
        },
        {
            name: 'Renato da Silva Costa',
            location: 'Itaquaquecetuba - SP'
        },
        {
            name: 'Elizandra Gabriela da Silva Veiga',
            location: 'Avaré - SP'
        },
        {
            name: 'Claudiana de Melo Faria Barros Pinto',
            location: 'Santos - SP'
        },
        {
            name: 'Silvio Silveira dos Santos',
            location: 'Jacareí - SP'
        },
        {
            name: 'Tatiana Rodrigues Nunes',
            location: 'São Gonçalo - RJ'
        },
        {
            name: 'Amanda Marcele de Azevedo',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Natalia Aparecida Santos Xavier',
            location: 'Presidente Prudente - SP'
        },
        {
            name: 'Mikaele kaline Verde',
            location: 'Bady Bassitt - SP'
        },
        {
            name: 'Salviano amancio de Souza',
            location: 'Caieiras - SP'
        },
        ]
    }, {
        month: 'Agosto 2020',
        winners: [{
            name: 'Carlos Alexandro Pereira Fraga',
            location: 'Cabo Frio - RJ'
        },
        {
            name: 'José J Guimarães',
            location: 'São Paulo - SP'
        },
        {
            name: 'Valdir Cavalcante Porto',
            location: 'São Paulo - SP'
        },
        {
            name: 'Alexsandro Correia Da Silva',
            location: 'Mauá - SP'
        },
        {
            name: 'Maicon da Silva Boeira ',
            location: 'Caxias do Sul - RS'
        },
        {
            name: 'Emerson Rodrigues da Paz Gomes',
            location: 'Mauá - SP'
        },
        {
            name: 'Matheus E da Silva',
            location: 'São Gonçalo - RJ'
        },
        {
            name: 'Luciano Ciasca Giaretta',
            location: 'São Paulo - SP'
        },
        {
            name: 'Felipe Tarcilio Yanatelli Amorim Lage ',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Pamela Soares Mota',
            location: 'São Paulo - SP'
        },
        ]
    }, {
        month: 'Julho 2020',
        winners: [{
            name: 'Raphael Gustavo Corrêa dos Reis',
            location: 'Jacareí - SP'
        },
        {
            name: 'Alan de Almeida Gomes',
            location: 'Nova Iguaçu - RJ'
        },
        {
            name: 'Marcio de Lima Bruno',
            location: 'Duque de Caxias - RJ'
        },
        {
            name: 'Dagmar de Oliveira Melo',
            location: 'Rio das Ostras - RJ'
        },
        {
            name: 'Frank William de Oliveira ',
            location: 'Cachoeira Paulista - SP'
        },
        {
            name: 'Marco Antônio de Souza',
            location: 'São Paulo - SP'
        },
        {
            name: 'Jaderson De Lemos Sivinski',
            location: 'Sapucaia do Sul - RS'
        },
        {
            name: 'Philippe Augusto da Cunha Silva',
            location: 'São Paulo - SP'
        },
        {
            name: 'Elizandra Gabriela da Silva Veiga',
            location: 'Avaré - SP'
        },
        {
            name: 'Cristiano da Silva Elizeu',
            location: 'Maricá - RJ'
        },
        ]
    }, {
        month: 'Junho 2020',
        winners: [{
            name: 'José Ricardo Semensatto Ferrazza',
            location: 'São Carlos - SP'
        },
        {
            name: 'Otavio Cremonez Eufrazio',
            location: 'Araraquara - SP'
        },
        {
            name: 'Diogo Lima do Nascimento',
            location: 'Santa Bárbara D Oeste - SP'
        },
        {
            name: 'Tibério Francisco de Oliveira',
            location: 'Diadema - SP'
        },
        {
            name: 'Marcos Anjos',
            location: 'São Paulo - SP'
        },
        {
            name: 'Bruna Elizabeth Lee An',
            location: 'São Paulo - SP'
        },
        {
            name: 'Alessandro Teixeira Hilario',
            location: 'Rio de Janeiro - RJ'
        },
        {
            name: 'Tiago Grillo Lermy',
            location: 'Santos - SP'
        },
        {
            name: 'Fellipe Muniz Pereira dos Santos',
            location: 'São Paulo - SP'
        },
        {
            name: 'Jose Henrique Alves Vidal',
            location: 'Pelotas - RS'
        },
        ]
    }, {
        month: 'Maio 2020',
        winners: [{
            name: 'Mariel Rocha de Jesus',
            location: 'São Carlos - SP'
        },
        {
            name: 'Jose Domingos Rezende Nalesso',
            location: 'Itapetininga - SP'
        },
        {
            name: 'Bruno Eduardo de Oliveira',
            location: 'Araraquara - SP'
        },
        {
            name: 'Eunice Toshie Shinmachi Silva',
            location: 'São Caetano do Sul - SP'
        },
        {
            name: 'Ana Julia Ayres',
            location: 'Itapetininga - SP'
        },
        {
            name: 'Wagner Nishioka ',
            location: 'Barueri - SP'
        },
        {
            name: 'Camila Luz Dourado Costa',
            location: 'São Paulo - SP'
        },
        {
            name: 'Priscilla Wanessa Fanis',
            location: 'Santo Antônio de Posse - SP'
        },
        {
            name: 'Thalita Tavares Coelho',
            location: 'Cotia - SP'
        },
        {
            name: 'F Nasser',
            location: 'São Paulo - SP'
        },
        ]
    }
];